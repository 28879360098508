<template>
  <div>
    <router-view/>
    <ReturnHead></ReturnHead>
  </div>
</template>
<script>
  import ReturnHead from './components/ReturnHead/index.vue'
  export default{
    components:{ReturnHead}
  }
</script>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  min-width: 1600px;
}


nav {
  // padding: 30px;
  *{
    margin: 0;
    padding: 0;
    border:0
  }
  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}


.line-1{
    white-space:nowrap!important;
    overflow:hidden!important;
    text-overflow:ellipsis!important;
}

.line-1-1 { 
    white-space: normal !important;
    display: -webkit-box !important;
    text-overflow: ellipsis !important;
    -webkit-line-clamp: 1 !important;
    -webkit-box-orient: vertical !important;
    overflow: hidden !important;
  }

.line-2{
    white-space:normal!important;
    display:-webkit-box!important;
    text-overflow:ellipsis!important;
    -webkit-line-clamp:2!important;
    -webkit-box-orient:vertical!important;
    overflow: hidden!important;
}

.line-3{
    white-space:normal!important;
    display:-webkit-box!important;
    text-overflow:ellipsis!important;
    -webkit-line-clamp:3!important;
    -webkit-box-orient:vertical!important;
    overflow: hidden!important;
}
</style>
