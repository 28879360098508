import { createRouter, createWebHashHistory } from 'vue-router'


const routes = [
  {
    path: '/',
    redirect: '/IndexView'
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  },
  {
    path: '/home',
    name: 'home',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/HomeView.vue')
  },
  {
    path: '/IndexView',
    name: 'IndexView',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/IndexView.vue')
  }
  ,
  {
    path: '/productsIntroduction',
    name: 'productsIntroduction',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/productsIntroduction.vue')
    // component: () => import(/* webpackChunkName: "about" */ '../views/index.vue')
  },
  {
    path: '/productAdvantages',
    name: 'productAdvantages',
    component: () => import(/* webpackChunkName: "about" */ '../views/productAdvantages.vue')
    // component: () => import(/* webpackChunkName: "about" */ '../views/index.vue')
  },
  {
    path: '/YoujiaApp',
    name: 'YoujiaApp',
    component: () => import(/* webpackChunkName: "about" */ '../views/YoujiaApp.vue')
  },
  {
    path: '/OperationManagement',
    name: 'OperationManagement',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/OperationManagement.vue')
    // component: () => import(/* webpackChunkName: "about" */ '../views/index.vue')
  },
  {
    path: '/CasePresentation',
    name: 'CasePresentation',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/CasePresentation.vue')
    // component: () => import(/* webpackChunkName: "about" */ '../views/index.vue')
  },
  {
    path: '/NewsInformation',
    name: 'NewsInformation',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/NewsInformation.vue')
    // component: () => import(/* webpackChunkName: "about" */ '../views/index.vue')
  },
  {
    path: '/AboutBoluo',
    name: 'AboutBoluo',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutBoluo.vue')
    // component: () => import(/* webpackChunkName: "about" */ '../views/index.vue')
  },
  {
    path: '/NewsDeTail',
    name: 'NewsDeTail',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/NewsDeTail.vue')
    // component: () => import(/* webpackChunkName: "about" */ '../views/index.vue')
  },
  {
    path: '/NewsDeTail_1',
    name: 'NewsDeTail_1',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/NewsDeTail_1.vue')
    // component: () => import(/* webpackChunkName: "about" */ '../views/index.vue')
  },
  {
    path: '/NursingHomeServices',
    name: 'NursingHomeServices',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/NursingHomeServices.vue')
    // component: () => import(/* webpackChunkName: "about" */ '../views/index.vue')
  },
  {
    path: '/Cabinets_page',
    name: 'Cabinets_page',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Cabinets_page.vue')
    // component: () => import(/* webpackChunkName: "about" */ '../views/index.vue')
  },
  
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
