<template>
  <div class="content">
  <img  v-show="isShow" @click="goBack" class="go_top" src="./image/go_top.png" alt="">
  </div>
</template>

<script>
export default {
  name:'ReturnHead',
  data(){
    return{
      scrollTop: 0, //默认距离顶部的距离
      isShow: false, //控制返回顶部按钮的显隐
      scrollTrigger: false, //默认初始值
      show: false,
      hidden: false,

    }
  },
  methods:{
    // 返回顶部事件
    goBack() {
      let that = this; // 防止this指向问题
      // 防止频繁点击，故返回顶部后设置scrollTrigger为初始值
      if (that.scrollTrigger) {
        return;
      }
      // 获取当前距离顶部的距离
      let scrollTop = that.scrollTop;
      let steep = scrollTop / 2000;
      let timer = setInterval(() => {
        that.scrollTrigger = true;
        // 滚动的速度逐渐变快，第一次走2000/1，然后减去已走的距离，下一次用剩下的距离再减去步进值，步进值也是不断变化，这样速度会越来越快
        scrollTop -= steep;
        // 步进值不改变的话会匀速缓慢上滑，不断增加步进值上滑的距离增加，视觉效果速度变快
        steep += 20;
        if (scrollTop <= 0) {
          clearInterval(timer);
          that.scrollTrigger = false;
        }
        let scroll =
          document.documentElement ||
          document.body ||
          document.querySelector(".content");
        scroll.scrollTop = scrollTop;
      }, 30);
    },
  },
  mounted() {
    let _this = this;
    // 获取滚动条高度
    window.addEventListener(
      "scroll",
      () => {
        if (!this.hidden) {
          let scrollTop =
            document.documentElement.scrollTop ||
            document.body.scrollTop ||
            document.querySelector(".content").scrollTop;
          _this.scrollTop = scrollTop;
          // 判断距离顶部多少显示回到顶部图标
          if (scrollTop > 300) {
            _this.isShow = true;
          } else {
            _this.isShow = false;
          }
        }
      },
      true
    );
  },
}
</script>

<style lang="scss">
  .go_top{
    width: 44px;
    height: 44px;
    z-index: 10000;
    position: fixed;
    top: 80vh;
    right: 50px;
  }
</style>